import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, Grid } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import styles from './NumRangeColumnFilter.css';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NumRangeColumnFilter = props => {
  const { value, onChange, onEnter } = props;

  const ref = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleChange = (event, type) => {
    let [from, to] = value;

    if (type === 'from') {
      from = event.target.value ? parseFloat(event.target.value) : null;
      if (isNaN(from)) {
        from = null;
      }
    }

    if (type === 'to') {
      to = event.target.value ? parseFloat(event.target.value) : null;
      if (isNaN(to)) {
        to = null;
      }
    }

    onChange([from, to]);
  };

  const handleClear = type => {
    let [from, to] = value;

    if (type === 'from') {
      from = null;
    }

    if (type === 'to') {
      to = null;
    }

    onChange([from, to]);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onEnter();
    }
  };

  const [from, to] = value;

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          inputRef={ref}
          id="num-range-from"
          type="number"
          InputProps={{
            classes: {
              root: styles.root,
              input: styles.input
            },
            inputMode: {
              inputMode: 'numeric',
              pattern: '[0-9.]*'
            },
            endAdornment: (from !== null) ? (
              <IconButton size="small" onClick={() => handleClear('from')}>
                <Clear />
              </IconButton>
            ) : ''
          }}
          size={'small'}
          className={styles.textField}
          label="От"
          variant="outlined"
          value={from !== null ? from : ''}
          onChange={event => handleChange(event, 'from')}
          onKeyDown={handleKeyDown}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id="num-range-to"
          type="number"
          InputProps={{
            classes: {
              root: styles.root,
              input: styles.input
            },
            inputMode: {
              inputMode: 'numeric',
              pattern: '[0-9.]*'
            },
            endAdornment: (to !== null) ? (
              <IconButton size="small" onClick={() => handleClear('to')}>
                <Clear />
              </IconButton>
            ) : ''
          }}
          size={'small'}
          className={styles.textField}
          label="До"
          variant="outlined"
          value={to !== null ? to : ''}
          onChange={event => handleChange(event, 'to')}
          onKeyDown={handleKeyDown}
        />
      </Grid>
    </Grid>
  );
};

NumRangeColumnFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired
};

export default NumRangeColumnFilter;