import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import styles from './SearchColumnFilter.css';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SearchColumnFilter = props => {
  const { value, onChange, onEnter } = props;

  const ref = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleChange = event => {
    const value = event.target.value;
    onChange(value !== '' ? value : null);
  };

  const handleClear = () => {
    onChange(null);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      onEnter();
    }
  };

  return (
    <TextField
      fullWidth
      inputRef={ref}
      id="search"
      InputProps={{
        classes: {
          root: styles.root,
          input: styles.input
        },
        endAdornment: (value !== null) ? (
          <IconButton size="small" onClick={handleClear}>
            <Clear />
          </IconButton>
        ) : ''
      }}
      size={'small'}
      className={styles.textField}
      label="Введите значение"
      variant="outlined"
      value={value !== null ? value : ''}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  );
};

SearchColumnFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired
};

export default SearchColumnFilter;