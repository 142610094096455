export default {
  mobileFirefoxAlert: 'Тапсырманы өңдеу бұл шолғышта қол жетімді емес',
  datePicker: {
    from: 'Қайдан',
    to: 'Бұрын',
    cancel: 'Болдырмау',
    apply: 'Өтініш',
    month: [
      'Қаңтар',
      'Ақпан',
      'Наурыз',
      'Сәуір',
      'Мамыр',
      'Маусым',
      'Шілде',
      'Тамыз',
      'Қыркүйек',
      'Қазан',
      'Қараша',
      'Желтоқсан'
    ],
    week: 'апта',
    weekShot: 'апта',
    buttonName: {
      date: 'Күні',
      week: 'Апта',
      month: 'Ай'
    },
    modal: {
      header: 'Назар аударыңыз!',
      text: 'Жарамсыз күндер ауқымы таңдалды',
      action: 'ОК'
    }
  },
  filters: {
    name: 'Аты'
  },
  header: {
    logout: 'Шығу',
    changePassword: 'Құпия сөзді өзгерту',
    settings: 'Параметрлер',
    title: 'Посмотреть интерфейс пользователя',
    searchTitle: 'Поиск пользователя',
    input: 'Начните вводить имя или логин…',
    cancel: 'Отмена',
    view: 'Просмотр',
    id: 'ID',
    login: 'Логин',
    name: 'Имя',
    phone: 'Телефон',
    email: 'Почта',
    stopSettings: 'Остановить просмотр'
  },
  scenario: {
    noDataReceived: 'Деректер алынған жоқ',
    filterPart: {
      name: 'Аты',
      clear: 'Таза',
      notFound: 'Табылмады',
      search: 'Іздеу...'
    }
  },
  directTask: {
    addCase: 'Іс қосу',
    addComment: 'Түсініктеме',
    actions: 'Әрекеттер',
    searchProduct: 'PLU/Barcode',
    searchProductShort: 'PLU/Barcode',
    photo: 'Фото',
    back: 'Оралу',
    treeEmpty: 'БОЛМАЙТЫН ӘРЕКЕТТЕР',
    step: 'Қадам',
    sortingButton: 'Сұрыптау',
    sortButton: 'Сұрыптау',
    add: 'Қосу',
    delete: 'Жою',
    deleteManualCases: 'Істі жою',
    show: 'Көрсету',
    hide: 'Жасыру',
    comment: 'Түсініктеме',
    showDoneCases: 'Қолданылған жағдайларды көрсету',
    hideDoneCases: 'Қолданылған жағдайларды жасыру'
  },
  gridTable: {
    pluList: 'Тізім PLU'
  },
  casesForm: {
    product: 'Өнім',
    loadFoto: 'Фотосуретті жүктеңіз',
    plu: 'Тақырыпты енгізіңіз/PLU',
    success: 'Іс жасалды',
    add: 'құру',
    new: 'Жаңа',
    delete: 'Өшіру',
    cancel: 'Болдырмау',
    comment: 'Түсініктеме'
  },
  pagination: {
    apply: 'Алға',
    placeholder: 'бөлме'
  },
  restorePass: {
    noLength: 'Пароль 12 таңбадан аз',
    noLowercase: 'Кем дегенде бір кіші әріпті қажет етеді',
    noUppercase: 'Требуется как минимум одна прописная буква',
    noNumber: 'Кем дегенде бір бас әріп қажет',
    noMatch: 'Құпия сөздер сәйкес келмейді',
    password: 'құпиясөз',
    repeatPassword: 'Құпия сөзді қайталаңыз',
    ok: 'Растаңыз',
    cancel: 'Болдырмау'
  },
  navigation: {
    missions: 'Миссиялар',
    scenarios: 'Сценарийлер'
  },
  changePassword: {
    previousPassword: 'Алдыңғы пароль',
    newPassword: 'Жаңа пароль',
    confirmPassword: 'Жаңа құпия сөз',
    apply: 'Өтініш',
    title: 'Құпия сөзді өзгерту',
    success: 'Құпия сөз сәтті өзгертілді',
    wrongPassword: 'Құпия сөз дұрыс емес',
    weakPassword: 'Құпия сөз өлшемдерге сәйкес келмейді',
    passwordsNotMatch: 'Құпия сөздер сәйкес келмейді',
    requirements:
      'Құпия сөз кемінде 12 таңбадан тұруы керек, оның ішінде бір бас әріп, бір бас әріп және бір сан '
  },
  auth: {
    login: 'Логин',
    password: 'құпиясөз',
    restorePass: 'Құпия сөзді қалпына келтіріңіз',
    submit: 'Жүйеге кіру',
    loginError: 'Логин немесе пароль дұрыс емес'
  },
  restorePassForm: {
    restore: 'Қалпына келтіру',
    cancel: 'Болдырмау',
    info: 'OSA Hybrid платформасында тіркелген электрондық поштаңызды енгізіңіз',
    emailInvalid: 'Электрондық пошта табылмады',
    email: 'Email',
    success: 'Құпия сөзді қалпына келтіру сілтемесі электрондық поштаңызға жіберілді'
  },
  search: {
    notFound: 'Табылмады'
  },
  noMatchPage: {
    title: 'Сұралған бет табылмады',
    body: 'Басты бетке оралу'
  },
  warnings: {
    title: 'Назар аударыңыз!',
    isDesktop:
      'Фотосуреттерді жүктеу тек жүйенің мобильді нұсқасында қол жетімді. Телефонды немесе планшеттік камераны пайдаланыңыз.'
  },
  errors: {
    netErrorText: 'Желі қатесі. Бетті қайта жүктеңіз',
    netErrorReload: 'Қайта жүктеңіз',
    netErrorClose: 'Жабық',
    netErrorTitle: 'Қате',
    noShops: 'О! Сізге әлі дүкен тағайындалмаған сияқты. Бұл туралы менеджеріңізге айтыңыз.',
    noCases:
      'Біздің қызмет бұл дүкеннен ешқандай жағдай таба алмаған сияқты. Оларды өзіңіз жасай бастаңыз.',
    noAutoOrder: 'ADR түзетуге арналған тапсырмалар жоқ.',
    causeError: 'Қате',
    scenariosEmpty: 'Сізде бір сценарий жоқ'
  },
  officeUI: {
    resetZoom: 'Масштабты қалпына келтіру'
  },
  gridAutocomplete: {
    placeholder: 'Дүкен атауын теруді бастаңыз',
    notFound: 'Дүкен табылмады'
  },
  targets: {
    nothing: '',
    shop: 'Гол',
    category: 'Санат',
    supplier: 'Провайдер',
    vendor: 'сатушы',
    brand: 'Тауар атауы',
    plu: 'PLU',
    day: 'күн',
    week: 'апта',
    month: 'Ай',
    quarter: 'тоқсан',
    year: 'жыл'
  },
  bubbleChart: {
    qty: 'дана',
    currency: 'тенге'
  },
  chart: {
    quarter: 'тоқсан',
    week: 'апта'
  },
  download: {
    CSV: 'Жүктеу CSV',
    XLS: 'Жүктеу XLS'
  },
  counterTask: {
    task: 'Тапсырмалар бар',
    noTask: 'Тапсырмалар жоқ'
  },
  filtersShop: {
    shopName: 'Дүкеннің мекен-жайы',
    shopId: 'Дүкеннің атауы'
  },
  chartCaption: {
    thousand: 'мың',
    million: 'млн',
    billion: 'млрд'
  },
  common: {
    close: 'Жабық',
    userNotFound: 'Пайдаланушы табылмады'
  },
  virtualStock: {
    headers: {
      case_id: "ID",
      plu_original: "Код товара",
      plu: "Внутр. ID товара",
      shop_id: "ID магазина",
      ass_type: "Тип ассортимента",
      category_id3: "ID секции",
      category_id4: "ID категории",
      stock: "Объем остатков",
      stock_sum: "Сумма остатков",
      product_turnover: "Оборачиваемость",
      days_without_sales: "Дней без продаж",
      last_sales_date: "Дата посл. продажи",
      last_sales_volume: "Объем посл. продажи",
      last_shipment_date: "Дата посл. поставки",
      last_shipment_volume: "Объем посл. поставки",
      promo_start: "Начало промо-акции",
      promo_end: "Конец промо-акции",
      promo_period: "Период промо",
      is_promo: "Промо",
      title: "Название",
      category_name3: "Секция",
      category_name4: "Категория"
    }
  }
};
