import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, TextField } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import cls from 'classnames';
import styles from './SwitchColumnFilter.css';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SwitchColumnFilter = props => {
  const { value, onChange } = props;

  const ref = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const handleChange = event => {
    let value = event.target.value ? Number(event.target.value) : null;
    if (isNaN(value)) {
      value = null;
    }
    onChange(value);
  };

  const handleClear = () => {
    onChange(null);
  };

  return (
    <div>
      <TextField
        fullWidth
        autoFocus
        select
        ref={ref}
        id="select"
        SelectProps={{
          classes: {
            select: cls(value !== null && styles.selectFilled),
            iconOutlined: styles.iconOutlined
          },
          endAdornment: (value !== null) ? (
            <IconButton size="small" onClick={handleClear}>
              <Clear />
            </IconButton>
          ) : ''
        }}
        value={value !== null ? value.toString() : ''}
        label="Выберите значение"
        variant="outlined"
        size="small"
        onChange={handleChange}
      >
        <MenuItem key={1} value={'1'}>Да</MenuItem>
        <MenuItem key={0} value={'0'}>Нет</MenuItem>
      </TextField>
    </div>
  );
};

SwitchColumnFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SwitchColumnFilter;