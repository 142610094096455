import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox } from '@material-ui/core';
import moment from 'moment';
import MissionsNavigation from '../../components/MissionsNavigation';
import styles from './VirtualStock.css';
import Autocomplete from '../../components/GridAutocomplete';
import { shopRepository } from '../../domain/models/Shop/ShopRepository';
import { useQuery } from '../../hooks/useQuery';
import { setPageLoadingStatus } from '../../store/actions/actions';
import Preload from '../../constructors/Preload';
import Table from './Table/Table';
import { virtualStockRepository } from '../../domain/models/VirtualStock/VirtualStockRepository';
import { Header } from './Header';
import { taskRepository } from '../../domain/models/Task/TaskRepository';
import ColumnEditor from './Table/ColumnEditor/ColumnEditor';

const preload = new Preload();
const ignoreHeaders = ['is_vs_case', 'vs_case_id', 'promo_start', 'promo_end', 'case_id', 'plu'];
const headersOrder = [
  'title', 'plu_original', 'category_name3', 'category_name4', 'stock', 'stock_sum',
  'last_sales_date', 'last_sales_volume', 'days_without_sales'
];

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const VirtualStock = props => {
  const { dispatch, language } = props;

  const history = useHistory();

  const [mission, setMission] = useState(null);
  const [scenario, setScenario] = useState(null);
  const [shops, setShops] = useState([]);
  const [shopsReady, setShopsReady] = useState(false);
  const [shopId, setShopId] = useState(null);
  const [items, setItems] = useState([]);
  const [itemsReady, setItemsReady] = useState(false);
  const [processedItems, setProcessedItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [filteredSelected, setFilteredSelected] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [orderedHeaders, setOrderedHeaders] = useState(null);
  const [filteredHeaders, setFilteredHeaders] = useState(null);
  const [activeOnly, setActiveOnly] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [taskCreating, setTaskCreating] = useState(false);
  const [taskCreatingProgress, setTaskCreatingProgress] = useState(null);
  const [taskCreatingResultInfo, setTaskCreatingResultInfo] = useState(null);

  const translation = useMemo(() => {
    return language.translation ? language.translation.virtualStock : {};
  }, [language]);

  useEffect(() => {
    const unsubscribe = preload.subscribe(promises => {
      dispatch(setPageLoadingStatus(promises.length > 0));
    });

    const query = new URLSearchParams(location.search);
    const mid = query.has('mid') ? Number(query.get('mid')) : JSON.parse(localStorage.mission).id;
    const sid = query.has('sid') ? Number(query.get('sid')) : JSON.parse(localStorage.scenario).id;

    const missions = localStorage.hasOwnProperty('missions') ? JSON.parse(localStorage.missions) : [];
    const mission = missions.find(({ id }) => id == mid);
    const scenario = mission ? mission.scenarios.find(({ id }) => id == sid) : null;

    if (!mission || !scenario) {
      history.push('/');
    }

    setMission(mission);
    setScenario(scenario);
    preload.add(loadShops(mission.id));

    // Unsubscribe if component will unmount
    return () => unsubscribe;
  }, []);

  useEffect(() => {
    if (!shopId) {
      setItems([]);
    } else {
      const promise = loadVirtualStock();
      promise.then(() => {
        setSelected([]);
      });
      preload.add(promise);
    }
  }, [shopId]);

  useEffect(() => {
    if (taskCreatingProgress && taskCreatingProgress[0] === taskCreatingProgress[1]) {
      setTaskCreatingResultInfo({
        processed: taskCreatingProgress[0],
        total: taskCreatingProgress[1]
      });
      setTimeout(() => {
        setTaskCreatingResultInfo(null);
      }, 3000);
    }
  }, [taskCreatingProgress]);

  useEffect(() => {
    setProcessing(true);
    if (!filteredHeaders && !orderedHeaders && !activeOnly) {
      setProcessedItems([...items]);
    } else {
      let processed = [...items];

      if (activeOnly) {
        processed = processed.filter(item => {
          return item.hasChildCase() === false;
        });
      }

      if (filteredHeaders) {
        processed = processed.filter(item => {
          for (let i = 0; i < filteredHeaders.length; i++) {
            const filteredHeader = filteredHeaders[i];
            if (!filteredHeader.filter.filter(item[filteredHeader.property])) {
              return false;
            }
          }

          return true;
        });
      }

      if (orderedHeaders) {
        const [header] = orderedHeaders;
        processed.sort((a, b) => {
          if (header.order === Header.ORDER_ASC) {
            return (a[header.property] <= b[header.property] || a[header.property] === null) ? -1 : 1;
          } else {
            return (a[header.property] >= b[header.property] || b[header.property] === null) ? -1 : 1;
          }
        });
      }

      const nextSelected = processed.filter(item => {
        return selected.includes(item.case_id);
      }).map(item => {
        return item.case_id;
      });

      const nextFilteredSelected = processed.filter(item => {
        return !item.hasChildCase() && nextSelected.includes(item.case_id);
      }).map(item => {
        return item.case_id;
      });

      setSelected(nextSelected);
      setFilteredSelected(nextFilteredSelected);
      setProcessedItems(processed);
    }
    setTimeout(() => setProcessing(false), 500);
  }, [items, filteredHeaders, orderedHeaders, activeOnly]);

  /**
   *
   * @param missionId
   * @returns {Promise<void>}
   */
  const loadShops = async missionId => {
    const items = await shopRepository.getAll({ missionId });

    let shopId = items.length && Number(items[0].id);

    if (localStorage.shopID && items.length) {
      const foundShop = items.find(item => Number(item.id) === Number(localStorage.shopID));
      if (foundShop) {
        shopId = Number(foundShop.id);
      }
    }

    setShopId(shopId);
    setShops(items);
    setShopsReady(true);
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const loadVirtualStock = async () => {
    setItemsReady(false);
    const items = await virtualStockRepository.getAll({
      missionId: mission.id,
      scenarioId: scenario.id,
      shopId
    });

    let headers = [];

    if (items.length) {
      headers = items[0]
        .getProps()
        .filter(v => !ignoreHeaders.includes(v))
        .map(property => {
          const name = translation.headers.hasOwnProperty(property) ? translation.headers[property] : property;
          return new Header(property, name);
        });

      headers.sort((a, b) => {
        let aIdx = headersOrder.indexOf(a.property);
        let bIdx = headersOrder.indexOf(b.property);

        aIdx = aIdx !== -1 ? aIdx : 1000;
        bIdx = bIdx !== -1 ? bIdx : 1000;

        return aIdx <= bIdx ? -1 : 1;
      });
    }

    setOrderedHeaders(null);
    setFilteredHeaders(null);
    setSelected([]);
    setFilteredSelected([]);
    setItems(items);
    setHeaders(headers);

    setItemsReady(true);
  };

  /**
   *
   * @param event
   * @param index
   * @param value
   */
  const handleShopChange = (event, index, value) => {
    setShopId(value);
  };

  /**
   *
   * @param keys
   */
  const handleRowSelect = keys => {
    const nextFilteredSelected = keys.filter(key => {
      return !processedItems[key].hasChildCase();
    }).map(key => {
      return processedItems[key].case_id;
    });
    const nextSelected = keys.map(key => {
      return processedItems[key].case_id;
    });
    setFilteredSelected(nextFilteredSelected);
    setSelected(nextSelected);
  };

  /**
   *
   * @param headers
   */
  const handleHeadersChange = headers => {
    let resetOrder = false;
    let resetFilters = false;

    headers.forEach(header => {
      if (!header.visible) {
        if (!header.isNotOrdered()) {
          header.resetOrder();
          resetOrder = true;
        }

        if (!header.filter.isEmpty()) {
          header.resetFilter();
          resetFilters = true;
        }
      }
    });

    if (resetOrder) {
      setOrderedHeaders(null);
    }

    if (resetFilters) {
      const filteredHeaders = headers.filter(header => {
        return header.hasNotEmptyFilter();
      });

      setFilteredHeaders([...filteredHeaders]);
    }

    setHeaders([...headers]);
  };

  /**
   *
   * @param header
   */
  const handleOrderChange = header => {
    headers.forEach(item => {
      if (item !== header) {
        item.resetOrder();
      }
    });

    setOrderedHeaders(header.isNotOrdered() ? null : [header]);
    setHeaders([...headers]);
  };

  /**
   *
   * @param headers
   */
  const handleFilterChange = headers => {
    const filteredHeaders = headers.filter(header => {
      return header.hasNotEmptyFilter();
    });

    setFilteredHeaders([...filteredHeaders]);
    setHeaders([...headers]);
  };

  /**
   *
   * @returns {string}
   */
  const getActionDateTime = () => {
    const aestTime = new Date().toLocaleString("en-US", { timeZone: 'Atlantic/Reykjavik' });
    const timeForUpdate = new Date(aestTime).toISOString();

    return moment(timeForUpdate).format("YYYY-MM-DD HH:mm:ss");
  };

  /**
   *
   * @returns {Promise<void>}
   */
  const handleTasksCreate = async () => {
    setProcessing(true);
    setTaskCreating(true);

    const toCreateTasks = items
      .filter(item => filteredSelected.includes(item.case_id))
      .map(item => {
        return {
          missionId: mission.id,
          shopId,
          plu: item.plu,
          caseType: 498,
          parentId: item.case_id,
          actionDateTime: getActionDateTime()
        };
      });

    const createdTasks = await taskRepository.addAll(toCreateTasks);

    const toUpdateTasks = [];
    toCreateTasks.forEach((task, i) => {
      const createdTask = createdTasks[i];
      if (createdTask && createdTask.hasOwnProperty('itemId') && createdTask.itemId) {
        toUpdateTasks.push({
          itemId: task.parentId,
          shopId,
          missionId: mission.id,
          actionTypeFrom: 0,
          actionTypeTo: -1,
          actionDateTime: getActionDateTime(),
          caseType: 499
        });
      }
    });

    await taskRepository.updateAll(toUpdateTasks);

    setProcessing(false);
    setTaskCreating(false);
    setTaskCreatingResultInfo({
      processed: toUpdateTasks.length,
      total: toCreateTasks.length
    });
  };

  const handleActiveOnlyChange = (a, status) => {
    setActiveOnly(status);
  };

  /**
   *
   */
  const handleAfterCreation = () => {
    setTaskCreatingResultInfo(null);
    setSelected([]);
    setFilteredSelected([]);
    setItems([]);

    const promise = loadVirtualStock();
    preload.add(promise);
  };

  return (
    <div className={cls(styles.pageWrap, selected.length > 0 ? styles.pageWrapPadding : '')}>
      <MissionsNavigation />
      <div>
        {shopsReady && shops.length > 0 && (
          <div>
            <div className={styles.actionWrap}>
              <div className={cls(styles.actionWrapSelect, styles.searchWidth)}>
                <Autocomplete
                  mission={mission}
                  shopsData={shops}
                  currentShop={shopId}
                  handleChangeShop={handleShopChange}
                  translation={language.translation}
                />
              </div>
            </div>
            {itemsReady && items.length > 0 && (
              <div>
                <div className={styles.tableInfo}>
                  <div className={styles.tableInfoCount}>
                    <div>Записей: <span>{processedItems.length}</span></div>
                    <label>
                      <Checkbox checked={activeOnly} onChange={handleActiveOnlyChange} />
                      Скрыть завершенные
                    </label>
                  </div>
                  <ColumnEditor
                    headers={headers}
                    onChange={handleHeadersChange}
                  />
                </div>
                <Table
                  items={items}
                  processedItems={processedItems}
                  headers={headers}
                  selected={selected}
                  onSelect={handleRowSelect}
                  onFilterChange={handleFilterChange}
                  onOrderChange={handleOrderChange}
                />
              </div>
            )}
            {itemsReady && items.length === 0 && (
              <div className={styles.itemsNotFound}>
                Ничего не найдено
              </div>
            )}
          </div>
        )}
        {shopsReady && shops.length === 0 && (
          <div className={styles.itemsNotFound}>
            Магазины не найдены
          </div>
        )}
        {shopsReady && shops.length !== 0 && !shopId && (
          <div className={styles.itemsNotFound}>
            Выберите магазин
          </div>
        )}
      </div>
      {itemsReady && filteredSelected.length > 0 && (
        <div className={styles.selectedContainer}>
          Выбрано {filteredSelected.length}
          <Button onClick={handleTasksCreate} color="primary" variant="contained" disabled={taskCreating}>
            {!taskCreating && (
              <span>Создать задачи</span>
            )}
            {taskCreating && (
              <span>создаются задачи</span>
            )}
          </Button>

        </div>
      )}
      {processing && (
        <div className={styles.processing} />
      )}
      {taskCreatingResultInfo && (
        <div className={styles.taskCreatingResultInfo}>
          <div className={styles.taskCreatingResultInfoBox}>
            <div>Создано задач {taskCreatingResultInfo.processed} из {taskCreatingResultInfo.total}</div>
            <Button className={styles.taskCreatingResultInfoBoxBtn} onClick={handleAfterCreation} color="primary" variant="outlined">
              Продолжить
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

VirtualStock.propTypes = {
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.object.isRequired
};

export default connect(
  store => {
    return {
      language: store.language
    };
  }
)(VirtualStock);
