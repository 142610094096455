import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { List, ListCheckbox } from 'react-toolbox/lib/list';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
// import { Dialog } from 'react-toolbox/lib/dialog';
import { IconButton } from 'react-toolbox/lib/button';
import styles from './ColumnEditor.css';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ColumnEditor = props => {
  const { headers, onChange } = props;

  const [active, setActive] = useState(false);
  const [map, setMap] = useState({});

  const handleOpen = () => {
    setActive(true);
  };

  const listTheme = {
    list: styles.listWrap
  };

  /**
   *
   * @param header
   * @param val
   */
  const handlerHeaderChange = (header, val) => {
    const nextMap = {
      ...map
    };
    nextMap[header.property] = [header, val];
    setMap(nextMap);
  };

  const handleCancel = () => {
    setActive(false);
    setMap({});
  };

  const handleOk = () => {
    setActive(false);
    Object.keys(map).forEach(property => {
      const [header, val] = map[property];
      header.setVisible(val);
    });

    onChange(headers);
  };

  const visibleLength = headers.filter(header => header.visible).length;

  return (
    <div className={styles.menuContainer}>
      <div className={styles.menuBeforeText}>
        Колонки <span>{visibleLength}</span>  из <span>{headers.length}</span>
      </div>
      <IconButton icon="edit" flat style={{ color: '#356F97' }} onClick={handleOpen} />
      <Dialog
        maxWidth="xs"
        aria-labelledby="column-filter-dialog"
        open={active}
      >
        <DialogTitle id="column-filter-dialog-title">Колонки</DialogTitle>
        <DialogContent dividers>
          <List selectable ripple theme={listTheme}>
            {headers.map(header => {
              return (
                <ListCheckbox
                  className={styles.listItem}
                  key={header.property}
                  disabled={header.visible && visibleLength === 1}
                  checked={map.hasOwnProperty(header.property) ? map[header.property][1] : header.visible}
                  caption={header.name}
                  onChange={val => handlerHeaderChange(header, val)}
                />
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Отмена
          </Button>
          <Button onClick={handleOk} color="primary" variant="outlined">
            ОК
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ColumnEditor.propTypes = {
  headers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default connect(
  store => {
    return {
      language: store.language
    };
  }
)(ColumnEditor);